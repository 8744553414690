
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Coupon List</h5>
      <div class="card">
        <div class="card-body">
          <div class="row mb-2 gy-2">
            <div class="col-sm-12 col-md-6 d-flex">
              <b-button variant="dark"
                @click.prevent="popupModalShow = true" type="button">
              <i class="ri ri-add-fill align-bottom"></i> <span class=""> Add coupon </span></b-button>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-inline text-sm-end">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex mb-0 align-items-center">
                    <b-form-input v-model="searchQuery" type="search" placeholder="Search code..." class="form-control"></b-form-input>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive mb-0">
            <b-table striped hover ref="selectableTable" :items="paginatedItems"
              :fields="columns" responsive="sm">
                <template v-slot:cell(check)="data">
                  <div style="width:3rem;" class="form-check text-center ms-auto">
                    <span >{{data.item.id}}</span>
                  </div>
                </template>
                <template v-slot:cell(status)="data">
                  <span class="badge" :class="data.item.is_active ? 'bg-success': 'bg-secondary'">{{data.item.is_active ? 'active':'inactive'}}</span>
                </template>
                <template v-slot:cell(start)="data">
                  <div style="width:5rem;" class="cursor-pointer">{{$filters.date_time(data.item.start, 1) }} </div>
                </template>
                <template v-slot:cell(end)="data">
                  <div style="width:5rem;" class="cursor-pointer">{{$filters.date_time(data.item.end, 1) }} </div>
                </template>
                <template v-slot:cell(action)="data">
                  <ul class="list-inline table-action m-0">
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" @click="initEdit(data.item)" class="text-success">
                        <i class="ri ri-edit-2-line"></i></a>
                    </li>
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" @click="deleteItem(data.item.id)" class="text-danger">
                        <i class="ri-delete-bin-2-line"></i></a>
                    </li>
                  </ul>
                </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <b-pagination class="pagination-rounded" v-model="paginate.currentPage" :limit="paginate.limit" 
              :total-rows="searchedItems.length" :per-page="paginate.perPage"></b-pagination>
            </div>
          </div>
        </div>
      </div>

      <b-modal v-model="popupModalShow" hide-footer centered
      :title="editMode? 'Update coupon':'Add coupon'"
      title-class="fs-6" @hide="initializeAll()">
        <coupon-form v-if="popupModalShow" @closeMe="initializeAll()"
        :editMode="editMode" :editItem="editItem" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import CouponForm from '@/components/forms/CouponForm.vue';

export default {
  components: { CouponForm },
  name: "coupon-list",
  data() {
    return {
      isLoading: false,
      popupModalShow: false,
      editMode: false,
      editItem: null,
      paginate:{
        currentPage: 1,
        perPage: 10,
        limit:3
      },
      pageOptions: [10, 25, 50, 100],
      searchQuery: '',
      columns: [
          {
            key: "check",
            label: "ID"
          },
          {
            key: "code",
            label: "Code",
            sortable: true
          },
          {
            key: "multiplier",
            label: "multiplier",
            sortable: true
          },
          {
            key: "status",
            label: "Status",
          },
          {
            key: "start",
            label: "Start date",
            sortable: true
          },
          {
            key: "end",
            label: "End date",
            sortable: true
          },
          "action"
      ]
    }
  },
  computed: {
    coupons(){
      return this.$store.state.coupons;
    },
    searchedItems(){
      return this.coupons.filter(item => item.code ? item.code.toLowerCase().includes(this.searchQuery.toLowerCase()) : false )
    },
    paginatedItems(){
      return this.searchedItems.slice(
        this.paginateStart,
        this.paginateEnd
      );
    },
    paginateStart(){
      return (this.paginate.currentPage - 1) * this.paginate.perPage;
    },
    paginateEnd(){
      return (this.paginate.currentPage - 1) * this.paginate.perPage + this.paginate.perPage;
    },
  },
  methods: {
    initEdit(items){
      this.editMode = true;
      this.editItem = {...items}
      this.popupModalShow = true  
    },
    deleteItem(itemId){
      this.confirmDelete({
        text: "You about to delete coupon permanently",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/coupons/${itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit('DELETE_COUPON', itemId)
            }
          })
        }
      });
    },
    initializeAll(){
      this.editMode = false;
      this.editItem = null;
      this.popupModalShow = false;
    },
  },
  created(){
    this.$store.dispatch("fetchCoupons")
  },
}

</script>

