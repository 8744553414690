<template>
    <form autocomplete="off" class="">
        <div class="row gy-3">
            
            <div class="col-md-6">
                <label class="form-label">
                    Start Date <span class="text-danger">*</span>
                </label>
                <flat-pickr
                    v-model="data.start"
                    :config="{wrap: true, 
                    altFormat: 'M j, Y H:i',
                    altInput: true,
                    dateFormat: 'Y-m-d H:i',
                    enableTime: true,
                    }"
                    placeholder="Select date"
                    class="form-control flatpickr-input"
                    :class="{ 'is-invalid': v$.data.start.$error }"
                ></flat-pickr>
                <div v-for="(item, index) in v$.data.start.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label">
                    End Date <span class="text-danger">*</span>
                </label>
                <flat-pickr
                    v-model="data.end"
                    :config="{wrap: true, 
                        altFormat: 'M j, Y H:i',
                        altInput: true,
                        dateFormat: 'Y-m-d H:i',
                        enableTime: true,
                        minDate: new Date(),
                    }"
                    placeholder="Select date"
s                   class="form-control flatpickr-input"
                    :class="{ 'is-invalid': v$.data.end.$error }"
              ></flat-pickr>
                <div v-for="(item, index) in v$.data.end.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label for="inputCode">
                    Code <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.code"
                    :class="{ 'is-invalid': v$.data.code.$error }"
                    id="inputCode" name="code" class="form-control"
                    placeholder="Enter code" />
                <div v-for="(item, index) in v$.data.code.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label">
                    Multiplier <span class="text-danger">*</span>
                </label>
                <input type="number" v-model="data.multiplier" class="form-control"
                name="multiplier" :class="{ 'is-invalid': v$.data.multiplier.$error }" placeholder="E.g 0.1" />
                <div v-for="(item, index) in v$.data.multiplier.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <label class="form-label">Description</label>
                <textarea v-model="data.description" class="form-control"
                rows="2" placeholder="Write Description" name="description"></textarea>
            </div>
            <div class="col-6 col-md-4">
                <div class="mb-3">
                    <b-form-checkbox v-model="data.is_active" name="is_active" switch>
                        Active
                    </b-form-checkbox>
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="dark" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components:{
        flatPickr,
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                code: "",
                multiplier: null,
                start: null,
                end: null,
                is_active: false,
                description: ""
            },
        }
    },
    validations: {
        data:{
            code: {required: required},            
            multiplier: {required: required},            
            start: {required: required},            
            end: {required: required},            
        }
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) return;
                let formData = new FormData();
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/coupons/create`, formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("ADD_COUPON", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
             this.v$.$validate().then(result =>{
                if (!result) return;
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/coupons/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$store.commit("UPDATE_COUPON", response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$filters.patchUpdatables(this.data, this.editItem)
            this.data.start = new Date(this.data.start)
            this.data.end = new Date(this.data.end)
        }
    }
}
</script>